import React from 'react';

// assets
import LocalisationIcon from '../../images/footer/localisation.svg';
import MailIcon from '../../images/footer/mail.svg';
import PhoneIcon from '../../images/footer/phone.svg';
import FbIcon from '../../images/footer/facebook.svg';
import LiIcon from '../../images/footer/linkedin.svg';
import twIcon from '../../images/footer/twitter.svg';
import InIcon from '../../images/footer/instagram.svg';

export const footerLinks = [
  {
    id: 1,
    title: 'Qui sommes nous',
    titleEN: 'Who are we',
    url: '/about-us',
    subLinks: [
      {
        id: 11,
        name: 'A propos',
        nameEN: 'About us',
        url: '/about-us',
      },
      {
        id: 12,
        name: 'Notre équipe',
        nameEN: 'Our team',
        url: '/our-team',
      },
      {
        id: 13,
        name: 'Nos Etats Financiers',
        nameEN: 'Our Financial Statements',
        url: '/financial-statements',
      },
    ],
  },
  {
    id: 2,
    title: 'Nos programmes',
    titleEN: 'Our programs',
    url: '/#programs-section',
    subLinks: [
      {
        id: 21,
        name: 'Elife',
        url: '/program-elife',
      },
      {
        id: 22,
        name: 'Brain Health',
        url: '/brain-health',
      },
      {
        id: 23,
        name: 'Smart Tunisia',
        url: '/smart-tunisia',
      },
      {
        id: 24,
        name: 'Signalétique Culturelle Numérique',
        nameEN: 'Digital Cultural Signage',
        url: '/signaletique',
      },
      {
        id: 25,
        name: 'The Dot',
        url: '/program-the-dot',
      },
      {
        id: 26,
        name: 'FEDxE',
        url: '/program-fedxe',
      },
    ],
  },
  {
    id: 3,
    title: 'Nos partenaires',
    titleEN: 'Our partners',
    url: '/#partners-section',
    subLinks: [
      {
        id: 31,
        name: 'Nos partenaires',
        nameEN: 'Our partners',
        url: '/partners',
      },
    ],
  },
  {
    id: 4,
    title: 'Actualités',
    titleEN: 'News',
    url: '/press',
    subLinks: [
      {
        id: 42,
        name: 'Liste presses',
        nameEN: 'Press list',
        url: '/press',
      },
    ],
  },
  {
    id: 5,
    title: 'Contact',
    url: '/contact',
    subLinks: [
      {
        id: 51,
        name: 'Contactez-nous',
        nameEN: 'Contact us',
        url: '/contact',
      },
    ],
  },
];

export const contacts = [
  {
    id: 5,
    iconName: 'localisation',
    iconAlt: 'Localisation',
    text: "Zone d'activité Kheireddine Kram (lac3) 13 rue Socrate 2015 La Goulette",
    textEN:
      'KHeireddine activity area (lac3) 18 street Socrate 2015 lagoulette',
  },
  {
    id: 6,
    iconName: 'phone',
    iconNameEN: 'téléphone',
    iconAlt: 'Phone number',
    text: '+216 71 130 160',
  },
  {
    id: 7,
    iconName: 'mail',
    iconAlt: 'Mail',
    text: 'contact@fondation-tunisie.org',
  },
];

export const socialLinks = [
  {
    id: 8,
    iconName: 'linkedin',
    iconAlt: 'LinkedIn link',
    url: 'https://www.linkedin.com/company/ftpd/',
  },
  {
    id: 9,
    iconName: 'facebook',
    iconAlt: 'Facebook link',
    url: 'https://www.facebook.com/FondationTunisie/',
  },
  {
    id: 10,
    iconName: 'twitter',
    iconAlt: 'Twitter link',
    url: 'https://twitter.com/tunisie_le',
  },
  {
    id: 11,
    iconName: 'instagram',
    iconAlt: 'Instagram link',
    url: 'https://www.instagram.com/ftpdtunisie/',
  },
];

export const copyright = 'Copyright 2022. fondationtunisie.org';

export const icons = {
  localisation: <img src={LocalisationIcon} alt="Localisation" />,
  phone: <img src={PhoneIcon} alt="Phone Number" />,
  mail: <img src={MailIcon} alt="Email" />,
  linkedin: <img src={LiIcon} alt="LinkedIn" />,
  facebook: <img src={FbIcon} alt="Facebook" />,
  twitter: <img src={twIcon} alt="Twitter" />,
  instagram: <img src={InIcon} alt="Instagram" />,
};
