/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';
import { Link } from 'gatsby-plugin-react-i18next';
import PropTypes from 'prop-types';

// import ui libraries

// assets

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function Dropdown({ subLinks, dropdown, hasDropDown, linkId }) {
  // localisation

  /* ----------------------------- LOCAL VARIABLES ---------------------------- */

  /* ---------------------------------- HOOKS --------------------------------- */

  /* ----------------------------- RENDER HELPERS ----------------------------- */

  /* ******************************** RENDERING ******************************* */
  return (
    <ul
      className={
        hasDropDown && dropdown.id === linkId && dropdown.isDroped
          ? 'subLinks-submenu'
          : 'subLinks-submenu hidden'
      }
    >
      {subLinks?.map((sublink) => {
        return (
          <li key={sublink.id}>
            <Link to={sublink.url}>{sublink.name}</Link>
          </li>
        );
      })}
    </ul>
  );
}

Dropdown.propTypes = {
  subLinks: PropTypes.arrayOf(PropTypes.string),
  linkId: PropTypes.number.isRequired,
  hasDropDown: PropTypes.bool.isRequired,
  dropdown: PropTypes.bool.isRequired,
};
Dropdown.defaultProps = {
  subLinks: undefined,
};

export default Dropdown;
