/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import * as React from 'react';
import { Link } from 'gatsby-plugin-react-i18next';
import { Col, Container, Row } from 'react-grid-system';
import { useTranslation } from 'react-i18next';

// UI lib comoponents

// style
import './index.scss';

// constants
import { footerLinks, socialLinks, contacts, copyright, icons } from './data';

function PageFooter() {
  /* ----------------------------- LOCAL VARIABLE ----------------------------- */

  // localisation
  const {
    i18n: { language: currentLanguage },
  } = useTranslation();

  /* ----------------------------- RENDER HELPERS ----------------------------- */
  function LinksMap(links) {
    return links.map(({ id, name, nameEN, url }) => (
      <Link key={id} className="page-footer__link" to={url}>
        {currentLanguage === 'fr' ? name : nameEN || name}
      </Link>
    ));
  }

  const PagesMap = footerLinks.map(({ id, title, titleEN, subLinks, url }) => (
    <Col key={id} lg={2.3} md={12} sm={12}>
      <Link className="page-footer__page-link" to={url}>
        {currentLanguage === 'fr' ? title : titleEN || title}
      </Link>
      {subLinks && LinksMap(subLinks)}
    </Col>
  ));

  const SocialLinksMap = socialLinks.map(({ id, iconName, url }) => {
    return (
      <a
        href={url}
        target="_blank"
        className="page-footer__social-link"
        key={id}
        rel="noreferrer"
      >
        {iconName && icons[iconName]}
      </a>
    );
  });

  const ContactsMap = contacts.map(({ id, iconName, text, textEN }) => {
    return (
      <Col key={id} lg={3} md={12} sm={12}>
        <div className="page-footer-info">
          <div className="page-footer-info__icon">
            {iconName && icons[iconName]}
          </div>
          <p className="page-footer-info__text">
            {currentLanguage === 'fr' ? text : textEN || text}
          </p>
        </div>
      </Col>
    );
  });

  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <footer className="page-footer">
      <div className="container scroll-reveal">
        <Container fluid>
          <Row align="start" className="page-footer-links">
            {PagesMap}
          </Row>
          <Row align="center" className="page-footer-contact">
            {ContactsMap}
            <Col lg={3} sm={12} md={12}>
              <div className="page-footer-social">{SocialLinksMap}</div>
            </Col>
          </Row>
          <p className="copyright">{copyright}</p>
        </Container>
      </div>
    </footer>
  );
}

PageFooter.defaultProps = {
  siteTitle: '',
};

export default PageFooter;
