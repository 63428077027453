/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import * as React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import axios from 'axios';

// UI lib component

// Local UI components
import PageHeader from '../PageHeader';
import PageFooter from '../PageFooter';

// Style
import './index.scss';
import { API_VISITS } from '../APIs';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */
/**
 * PageLayout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */
function PageLayout({ children, isTransparent }) {
  function addObserver(el) {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('active-scroll');
          observer.unobserve(entry.target);
        }
      });
    });
    observer.observe(el);
  }

  const scrollTrigger = React.useCallback((selector) => {
    let els = document.querySelectorAll(selector);
    els = Array.from(els);
    els.forEach((el) => {
      addObserver(el);
    });
  }, []);

  React.useEffect(() => {
    scrollTrigger('.scroll-reveal');
  }, [scrollTrigger]);

  React.useEffect(() => {
    async function handleVisits() {
      const visited = sessionStorage.getItem('visited');
      if (visited === 'false' || !visited) {
        const { data } = await axios.get(API_VISITS);

        if (!data?.data?.length > 0) {
          await axios.post(API_VISITS, {
            data: {
              visits: 0,
            },
          });
        } else {
          await axios.put(`${API_VISITS}/${data?.data[0]?.id}`, {
            data: {
              visits: +data?.data[0]?.attributes?.visits + 1,
            },
          });
        }
        sessionStorage.setItem('visited', true);
      }
    }
    handleVisits();
    // if (window !== 'undefined') {
    //   window.addEventListener('close', () => {
    //     sessionStorage.setItem('visited', false);
    //   });
    // }
  }, []);
  /* ********************************** HOOKS ********************************* */

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  /* ******************************** RENDERING ******************************* */

  return (
    <div className="page-layout">
      <PageHeader
        siteTitle={data.site.siteMetadata?.title || 'Title'}
        isTransparent={isTransparent}
      />
      <main>{children}</main>
      <PageFooter />
    </div>
  );
}

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  isTransparent: PropTypes.bool,
};

PageLayout.defaultProps = {
  isTransparent: false,
};

export default PageLayout;
