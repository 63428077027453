/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useState, useEffect, useCallback } from 'react';
import { Link, useI18next } from 'gatsby-plugin-react-i18next';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// import ui libraries
import { Container, Row } from 'react-grid-system';

// assets
import Logo from '../../images/logo.svg';

// Style
import './index.scss';
import Dropdown from '../UIKit/DropDown';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function PageHeader({ isTransparent }) {
  // localisation
  const { t } = useTranslation();
  const { changeLanguage, language } = useI18next();
  /* -------------------------------- contants -------------------------------- */

  /* ----------------------------- LOCAL VARIABLES ---------------------------- */
  const { links } = t('header', { returnObjects: true });

  let screenWidth;
  let mobileBreakpoint;
  let isMobile = false;
  // determine if its mobile device
  if (typeof window !== 'undefined') {
    screenWidth = document.body.clientWidth;
    mobileBreakpoint = 992;
    isMobile = screenWidth <= mobileBreakpoint;
  }

  /* ---------------------------------- HOOKS --------------------------------- */
  const [isOpen, setIsOpen] = useState(false);
  const [activeNav, setActiveNav] = useState(false);
  const [isLanguageOpen, setIsLanguageOpen] = useState(false);
  const [dropdown, setDropdown] = useState({ id: 0, isDroped: false });

  // Add no scroll to body when menu is open on mobile
  if (typeof window !== 'undefined') {
    if (isOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }
  const changeBackground = useCallback(() => {
    if (document.body.scrollTop >= 30) {
      setActiveNav(true);
      return;
    }
    setActiveNav(false);
  }, []);

  useEffect(() => {
    changeBackground();
    document.body.addEventListener('scroll', changeBackground, {
      passive: true,
    });
    return () => {
      document.body.removeEventListener('scroll', changeBackground, {
        passive: true,
      });
    };
  }, [changeBackground]);

  /* ----------------------------- RENDER HELPERS ----------------------------- */
  // links Header:
  const LinksMap = links?.map(
    ({ id, url, name, isHash = false, hasDropDown, subLinks }) => (
      <li key={id} className="page-header__link">
        {isHash ? (
          <a
            href={`${isMobile && hasDropDown ? '#' : `/${url}`}`}
            onMouseEnter={() => setDropdown({ id, isDroped: true })}
            onMouseLeave={() => setDropdown({ id: 0, isDroped: false })}
            onClick={() => {
              setIsOpen(false);
              setDropdown({ id, isDroped: !dropdown.isDroped ?? true });
            }}
            onBlur={() => setDropdown({ id: 0, isDroped: false })}
            className={`${hasDropDown ? 'dropdown' : ''}`}
          >
            <span>{name}</span>
            <Dropdown
              subLinks={subLinks}
              hasDropDown={hasDropDown}
              linkId={id}
              dropdown={dropdown}
            />
          </a>
        ) : (
          <Link
            to={url}
            onClick={() => setIsOpen(false)}
            activeClassName="active"
          >
            {name}
          </Link>
        )}
      </li>
    ),
  );

  function SelectLanguage() {
    return (
      <button
        type="button"
        className="select-lang"
        onClick={() => setIsLanguageOpen(!isLanguageOpen)}
      >
        {language}
        {isLanguageOpen && (
          <div
            className={`select-lang-options ${isLanguageOpen ? 'active' : ''}`}
          >
            <button
              type="button"
              className="select-lang__button"
              onClick={() => changeLanguage('en')}
            >
              en
            </button>
            <button
              type="button"
              className="select-lang__button"
              onClick={() => changeLanguage('fr')}
            >
              fr
            </button>
          </div>
        )}
      </button>
    );
  }

  /* ******************************** RENDERING ******************************* */
  return (
    <header
      className={`page-header ${isTransparent ? '' : 'bg-white'} ${
        activeNav ? 'active-bg' : ''
      } `}
    >
      <div className="page-header-container">
        <Container fluid>
          <Row justify="between" align="center">
            <Link to="/" className="logo-container">
              <img src={Logo} alt="Website Logo" />
            </Link>
            <button
              type="button"
              className="hamburger-container"
              aria-label="hamburger-container"
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            >
              <svg
                className={`hamburger-icon ${isOpen ? 'click' : ''}`}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
              >
                <path
                  className="path-1"
                  d="M4 8H20"
                  stroke="#e7343f"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  className="path-2"
                  d="M4 16H20"
                  stroke="#e7343f"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
            <ul className={`page-header-links ${isOpen ? 'open-menu' : ''} `}>
              {LinksMap}
              {SelectLanguage()}
            </ul>
          </Row>
        </Container>
      </div>
    </header>
  );
}

PageHeader.propTypes = {
  isTransparent: PropTypes.bool,
};

PageHeader.defaultProps = {
  isTransparent: false,
};

export default PageHeader;
